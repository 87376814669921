import React from "react";

import logo from "../assets/logo.jpg";

export const Header = () => {
  return (
    <div className="logo-img_wrapper">
      <img className="logo-img" src={logo} />
    </div>
  );
};
