export const mapResults = (participants) => {
  const scoreMap = new Map();
  const aggregatedData = [];

  participants.forEach((entry) => {
    const { name, score } = entry;

    if (scoreMap.has(name)) {
      const currentScore = scoreMap.get(name);
      scoreMap.set(name, currentScore + score);
    } else {
      scoreMap.set(name, score);
    }
  });

  scoreMap.forEach((totalScore, name) => {
    aggregatedData.push({ name, score: totalScore });
  });

  return aggregatedData;
};
