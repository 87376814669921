export const tuesdayParticipants = [
  { name: "Лосев Максим", score: 21 },
  { name: "Масляков Олег", score: 20 },
  { name: "Пугачёв Александр", score: 19 },
  { name: "Федерякин Александр", score: 18 },
  { name: "Рех Алексей", score: 16.5 },
  { name: "Зеленский Владислав", score: 16.5 },
  { name: "Асанян Тимур", score: 14.5 },
  { name: "Скляров Сергей", score: 14.5 },
  { name: "Ачеев Иван", score: 11.5 },
  { name: "Арутюнян Альберт", score: 11.5 },
  { name: "Власов Вячеслав", score: 11.5 },
  { name: "Нефёдов Виталий", score: 11.5 },
  { name: "Ковалев Михаил", score: 7.5 },
  { name: "Гуляев Александр", score: 7.5 },
  { name: "Нефёдова Василя", score: 7.5 },
  { name: "Бабаков Александр", score: 7.5 },
  { name: "Хохлов Илья", score: 3 },
  { name: "Джафарова Кристина", score: 3 },
  { name: "Змеев Павел", score: 3 },
  { name: "Борисенко Виталий", score: 3 },
  { name: "Стурки Виталий", score: 3 },
  { name: "Павлов Денис", score: 22 },
  { name: "Никитин Андрей", score: 21 },
  { name: "Скляров Сергей", score: 20 },
  { name: "Борисенко Виталий", score: 19 },
  { name: "Федерякин Александр", score: 17.5 },
  { name: "Власов Вячеслав", score: 17.5 },
  { name: "Петров Дмитрий", score: 15.5 },
  { name: "Гуляев Александр", score: 15.5 },
  { name: "Джафарова Кристина", score: 12.5 },
  { name: "Лосев Максим", score: 12.5 },
  { name: "Пугачёв Александр", score: 12.5 },
  { name: "Никитин Антон", score: 12.5 },
  { name: "Ковалев Михаил", score: 8.5 },
  { name: "Камнев Алексей", score: 8.5 },
  { name: "Масляков Олег", score: 8.5 },
  { name: "Змеев Павел", score: 8.5 },
  { name: "Хохлов Илья", score: 3.5 },
  { name: "Арутюнян Альберт", score: 3.5 },
  { name: "Нефёдов Виталий", score: 3.5 },
  { name: "Нефёдова Василя", score: 3.5 },
  { name: "Рех Алексей", score: 3.5 },
  { name: "Голоманидзе Роберт", score: 3.5 },
  { name: "Фомин Кирилл", score: 16 },
  { name: "Пугачёв Александр", score: 15 },
  { name: "Федерякин Александр", score: 14 },
  { name: "Масляков Олег", score: 14 },
  { name: "Петров Дмитрий", score: 13 },
  { name: "Джафарова Кристина", score: 11.5 },
  { name: "Гуляев Александр", score: 11.5 },
  { name: "Никитин Антон", score: 9.5 },
  { name: "Лосев Максим", score: 9.5 },
  { name: "Арутюнян Альберт", score: 6.5 },
  { name: "Борисенко Виталий", score: 6.5 },
  { name: "Ковалев Михаил", score: 6.5 },
  { name: "Бабаков Александр", score: 6.5 },
  { name: "Власов Вячеслав", score: 2.5 },
  { name: "Нефёдов Виталий", score: 2.5 },
  { name: "Рех Алексей", score: 2.5 },
  { name: "Нефёдова Василя", score: 2.5 },
  { name: "Пугачёв Александр", score: 16 },
  { name: "Корженский Андрей", score: 15 },
  { name: "Петров Дмитрий", score: 14 },
  { name: "Габаткин Кирилл", score: 13 },
  { name: "Масляков Олег", score: 11.5 },
  { name: "Жидков Владимир", score: 11.5 },
  { name: "Борисенко Виталий", score: 9.5 },
  { name: "Бабаков Александр", score: 9.5 },
  { name: "Ломакин Максим", score: 6.5 },
  { name: "Гуляев Александр", score: 6.5 },
  { name: "Нефёдова Василя", score: 6.5 },
  { name: "Ковалев Михаил", score: 6.5 },
  { name: "Смирнов Сергей", score: 2.5 },
  { name: "Власов Вячеслав", score: 2.5 },
  { name: "Нефёдов Виталий", score: 2.5 },
  { name: "Митрофанов Андрей", score: 2.5 },
  { name: "Никитин Антон", score: 19 },
  { name: "Масляков Олег", score: 18 },
  { name: "Самойлов Владимир", score: 17 },
  { name: "Лосев Максим", score: 16 },
  { name: "Скляров Сергей", score: 14.5 },
  { name: "Митрофанов Андрей", score: 14.5 },
  { name: "Павлов Денис", score: 12.5 },
  { name: "Чудин Илья", score: 12.5 },
  { name: "Зеленский Владислав", score: 9.5 },
  { name: "Корженский Андрей", score: 9.5 },
  { name: "Арутюнян Альберт", score: 9.5 },
  { name: "Змеев Павел", score: 9.5 },
  { name: "Нефёдов Виталий", score: 5.5 },
  { name: "Гуляев Александр", score: 5.5 },
  { name: "Петров Дмитрий", score: 5.5 },
  { name: "Камнев Алексей", score: 5.5 },
  { name: "Асанян Тимур", score: 2 },
  { name: "Нефёдова Василя", score: 2 },
  { name: "Борисенко Виталий", score: 2 },
  { name: "Жидков Владимир", score: 23 },
  { name: "Марков Иван", score: 22 },
  { name: "Масляков Олег", score: 21 },
  { name: "Рахманов Ариф", score: 20 },
  { name: "Корженский Андрей", score: 18.5 },
  { name: "Гуляев Александр", score: 18.5 },
  { name: "Швецов Сергей", score: 16.5 },
  { name: "Павлов Денис", score: 16.5 },
  { name: "Бабаков Александр", score: 13.5 },
  { name: "Хохлов Илья", score: 13.5 },
  { name: "Ягудин Руслан", score: 13.5 },
  { name: "Левкович Илья", score: 13.5 },
  { name: "Самойлов Владимир", score: 9.5 },
  { name: "Терзян Карпо", score: 9.5 },
  { name: "Петров Дмитрий", score: 9.5 },
  { name: "Нефёдова Василя", score: 9.5 },
  { name: "Голоманидзе Роберт", score: 4 },
  { name: "Камнев Алексей", score: 4 },
  { name: "Скляров Сергей", score: 4 },
  { name: "Нефёдов Виталий", score: 4 },
  { name: "Ковалев Михаил", score: 4 },
  { name: "Власов Вячеслав", score: 4 },
  { name: "Арутюнян Альберт", score: 4 },
  { name: "Корженский Андрей", score: 20 },
  { name: "Самойлов Владимир", score: 19 },
  { name: "Марков Иван", score: 18 },
  { name: "Пугачёв Александр", score: 17 },
  { name: "Федерякин Александр", score: 15.5 },
  { name: "Хохлов Илья", score: 15.5 },
  { name: "Петров Дмитрий", score: 13.5 },
  { name: "Никитин Антон", score: 13.5 },
  { name: "Борисенко Виталий", score: 10.5 },
  { name: "Чуев Максим", score: 10.5 },
  { name: "Бабаков Александр", score: 10.5 },
  { name: "Черных Антон", score: 10.5 },
  { name: "Хитров Сергей", score: 6.5 },
  { name: "Власов Вячеслав", score: 6.5 },
  { name: "Павлов Денис", score: 6.5 },
  { name: "Нефёдов Виталий", score: 6.5 },
  { name: "Масляков Олег", score: 2.5 },
  { name: "Нефёдова Василя", score: 2.5 },
  { name: "Джафарова Кристина", score: 2.5 },
  { name: "Гуляев Александр", score: 2.5 },
];

export const wednesdayParticipants = [
  "Никитин Андрей - 25 очков",
  "Климкина Карина - 24 очка",
  "Ачеев Иван - 23 очка",
  "Чарыев Максат - 22 очка",
  "Гурьянов Артемий - 20.5 очка",
  "Иванов Александр - 20.5 очка",
  "Карандашов Роман - 18.5 очка",
  "Чуев Максим - 18.5 очка",
  "Ахмурадов Бек - 16.5 очка",
  "Петров Вадим - 16.5 очка",
  "Комаров Алексей - 16.5 очка",
  "Никитин Антон - 16.5 очка",
  "Нураки Салах - 14.5 очка",
  "Нирмухамедов Мелис - 14.5 очка",
  "Нефедов Виталий - 14.5 очка",
  "Горбачева Галина - 14.5 очка",
  "Аблемова Зарина - 7.5 очка",
  "Зеленский Владислав - 7.5 очка",
  "Маланичев Дмитрий - 7.5 очка",
  "Жевалёв Николай - 7.5 очка",
  "Джафарова Кристина - 7.5 очка",
  "Антипов Вадим - 7.5 очка",
  "Змеев Павел - 7.5 очка",
  "Джеманкулов Марат - 7.5 очка",
  "Нефедова Василя - 7.5 очка",
  "Чумичкин Сергей - 29 очков",
  "Лосев Максим - 28 очков",
  "Никитин Андрей - 27 очков",
  "Горбачева Галина - 26 очков",
  "Федерякин Александр - 24.5 очка",
  "Пугачев Александр - 24.5 очка",
  "Швецов Кирилл - 22.5 очка",
  "Жевалёв Николай - 22.5 очка",
  "Зеленский Владислав - 19.5 очка",
  "Нураки Салах - 19.5 очка",
  "Гурьянов Артемий - 19.5 очка",
  "Арутюнян Альберт - 19.5 очка",
  "Никитин Антон - 15.5 очка",
  "Щеголев Станислав - 15.5 очка",
  "Чуев Максим - 15.5 очка",
  "Баранов Владимир - 15.5 очка",
  "Несолёнов Роман - 10.428 очка",
  "Кумсков Сергей - 10.428 очка",
  "Ачеев Иван - 10.428 очка",
  "Камнев Алексей - 10.428 очка",
  "Ахмурадов Бек - 10.428 очка",
  "Иванов Александр - 10.428 очка",
  "Карандашов Роман - 10.428 очка",
  "Змеев Павел - 3.5 очка",
  "Тылычныязов Язберди - 3.5 очка",
  "Джафарова Кристина - 3.5 очка",
  "Трофимов Владислав - 3.5 очка",
  "Петров Вадим - 3.5 очка",
  "Мещеряков Александр - 3.5 очка",
  "Самойлов Владимир - 27 очков",
  "Чуев Максим - 26 очков",
  "Нураки Салах - 25 очков",
  "Маврин Павел - 24 очка",
  "Петров Вадим - 22.5 очка",
  "Борисенко Виталий - 22.5 очка",
  "Карандашов Роман - 20.5 очка",
  "Швецов Кирилл - 20.5 очка",
  "Ачеев Иван - 17.5 очка",
  "Скляров Сергей - 17.5 очка",
  "Мещеряков Александр - 17.5 очка",
  "Ненахов Алексей - 17.5 очка",
  "Никитин Антон - 13.5 очка",
  "Гурьянов Артемий - 13.5 очка",
  "Змеев Павел - 13.5 очка",
  "Рыжов Денис - 13.5 очка",
  "Антипов Вадим - 8.5 очка",
  "Айнетдинов Шаукят - 8.5 очка",
  "Камнев Алексей - 8.5 очка",
  "Иванов Александр - 8.5 очка",
  "Маланичев Дмитрий - 8.5 очка",
  "Марков Иван - 8.5 очка",
  "Джафарова Кристина - 3 очка",
  "Камнев Виктор - 3 очка",
  "Зеленский Владислав - 3 очка",
  "Айнетдинов Андрей - 3 очка",
  "Климкина Карина - 3 очка",
  "Чумичкин Сергей - 29 очков",
  "Шелест Роман - 28 очков",
  "Петров Вадим - 27 очков",
  "Габаткин Кирилл - 26 очков",
  "Стешин Александр - 24.5 очка",
  "Борисенко Виталий - 24.5 очка",
  "Арутюнян Альберт - 22.5 очка",
  "Федерякин Александр - 22.5 очка",
  "Чуев Максим - 19.5 очка",
  "Марков Иван - 19.5 очка",
  "Гурьянов Артемий - 19.5 очка",
  "Маврин Павел - 19.5 очка",
  "Корженский Андрей - 16 очков",
  "Нураки Салах - 16 очков",
  "Мещеряков Александр - 16 очков",
  "Жевалёв Николай - 16 очков",
  "Никитин Антон - 16 очков",
  "Кумсков Сергей - 16 очков",
  "Ачеев Иван - 12.5 очка",
  "Маланичев Дмитрий - 12.5 очка",
  "Левин Михаил - 12.5 очка",
  "Камнев Алексей - 12.5 очка",
  "Зеленский Владислав - 12.5 очка",
  "Иванов Александр - 12.5 очка",
  "Джафарова Кристина - 8 очков",
  "Змеев Павел - 8 очков",
  "Айнетдинов Андрей - 8 очков",
  "Айнетдинов Шаукят - 8 очков",
  "Горбачева Галина - 8 очков",
  "Елизаров Евгений - 25 очков",
  "Лёвин Роман - 24 очка",
  "Ачеев Иван - 23 очка",
  "Корженский Андрей - 22 очка",
  "Зеленский Владислав - 20.5 очка",
  "Камнев Алексей - 20.5 очка",
  "Горбачева Галина - 18.5 очка",
  "Арутюнян Альберт - 18.5 очка",
  "Змеев Павел - 15.5 очка",
  "Федерякин Александр - 15.5 очка",
  "Никитин Антон - 15.5 очка",
  "Гурьянов Артемий - 15.5 очка",
  "Петров Вадим - 11.5 очка",
  "Антипов Вадим - 11.5 очка",
  "Маланичев Дмитрий - 11.5 очка",
  "Борисенко Виталий - 11.5 очка",
  "Джафарова Кристина - 5 очков",
  "Чуев Максим - 5 очков",
  "Иванов Александр - 5 очков",
  "Карандашов Роман - 5 очков",
  "Чудин Илья - 5 очков",
  "Мещеряков Александр - 5 очков",
  "Марков Иван - 5 очков",
  "Ненахов Алексей - 5 очков",
  "Самойлов Владимир - 5 очков",
  "Чумичкин Сергей - 25 очков",
  "Чудин Илья - 24 очка",
  "Швецов Кирилл - 23 очка",
  "Елизаров Евгений - 22 очка",
  "Самойлов Владимир - 20.5 очка",
  "Марков Иван - 20.5 очка",
  "Ачеев Иван - 18.5 очка",
  "Федерякин Александр - 18.5 очка",
  "Джанумов Роберт - 15.5 очка",
  "Стешин Александр - 15.5 очка",
  "Чуев Максим - 15.5 очка",
  "Ненахов Алексей - 15.5 очка",
  "Скляров Сергей - 11.5 очка",
  "Джафарова Кристина - 11.5 очка",
  "Никитин Антон - 11.5 очка",
  "Борисенко Виталий - 11.5 очка",
  "Карандашов Роман - 5 очков",
  "Зеленский Владислав - 5 очков",
  "Жевалёв Николай - 5 очков",
  "Арутюнян Альберт - 5 очков",
  "Змеев Павел - 5 очков",
  "Камнев Алексей - 5 очков",
  "Мещеряков Александр - 5 очков",
  "Нураки Салах - 5 очков",
  "Камнев Виктор - 5 очков",
  "Гурьянов Артемий - 5 очков",
  "Карандашов Роман - 20 очков",
  "Марков Иван - 19 очков",
  "Никитин Антон - 18 очков",
  "Чудин Илья - 17 очков",
  "Конкин Евгений - 15.5 очка",
  "Ачеев Иван - 15.5 очка",
  "Скляров Сергей - 13.5 очка",
  "Самойлов Владимир - 13.5 очка",
  "Борисенко Виталий - 10.5 очка",
  "Митрофанов Андрей - 10.5 очка",
  "Антипов Вадим - 10.5 очка",
  "Маланичев Дмитрий - 10.5 очка",
  "Джафарова Кристина - 6.5 очка",
  "Стешин Александр - 6.5 очка",
  "Мещеряков Александр - 6.5 очка",
  "Хохлов Илья - 6.5 очка",
  "Зеленский Владислав - 2.5 очка",
  "Иванов Александр - 2.5 очка",
  "Арутюнян Альберт - 2.5 очка",
  "Чуев Максим - 2.5 очка",
  "Федерякин Александр - 26 очков",
  "Чуев Максим - 25 очков",
  "Комаров Алексей - 24 очка",
  "Корженский Андрей - 23 очка",
  "Борисенко Виталий - 21.5 очка",
  "Иванов Александр - 21.5 очка",
  "Змеев Павел - 19.5 очка",
  "Арутюнян Альберт - 19.5 очка",
  "Никитин Антон - 16.5 очка",
  "Джанумов Роберт - 16.5 очка",
  "Лосев Максим - 16.5 очка",
  "Мещеряков Александр - 16.5 очка",
  "Джафарова Кристина - 12.5 очка",
  "Стешин Александр - 12.5 очка",
  "Голоманидзе Роберт - 12.5 очка",
  "Гурьянов Артемий - 12.5 очка",
  "Петров Вадим - 5.5 очка",
  "Климкина Карина - 5.5 очка",
  "Маланичев Дмитрий - 5.5 очка",
  "Камнев Алексей - 5.5 очка",
  "Жевалёв Николай - 5.5 очка",
  "Шебалдов Дмитрий - 5.5 очка",
  "Антипов Вадим - 5.5 очка",
  "Ачеев Иван - 5.5 очка",
  "Зеленский Владислав - 5.5 очка",
  "Коржов Роман - 5.5 очка",
  "Гордиенко Николай - 25 очков",
  "Конкин Роман - 24 очка",
  "Чуев Максим - 23 очка",
  "Самойлов Владимир - 22 очка",
  "Джафарова Кристина - 20.5 очка",
  "Ачеев Артём - 20.5 очка",
  "Савин Александр - 18.5 очка",
  "Маланичев Дмитрий - 18.5 очка",
  "Карев Сергей - 15.5 очка",
  "Нураки Салах - 15.5 очка",
  "Зеленский Владислав - 15.5 очка",
  "Змеев Павел - 15.5 очка",
  "Мещеряков Александр - 11.5 очка",
  "Арутюнян Альберт - 11.5 очка",
  "Корженский Андрей - 11.5 очка",
  "Коржов Роман - 11.5 очка",
  "Окороков Сергей - 5 очков",
  "Климкина Карина - 5 очков",
  "Голоманидзе Роберт - 5 очков",
  "Ачеев Иван - 5 очков",
  "Хитров Сергей - 5 очков",
  "Петров Вадим - 5 очков",
  "Борисенко Виталий - 5 очков",
  "Гурьянов Артемий - 5 очков",
  "Иванов Александр - 5 очков",
  "Гурьянов Артемий - 33 очка",
  "Чарыев Максат - 32 очка",
  "Борисенко Виталий - 31 очко",
  "Нураки Салах - 30 очков",
  "Чудин Илья - 28.5 очка",
  "Соколов Иван - 28.5 очка",
  "Мещеряков Александр - 26.5 очка",
  "Корженский Андрей - 26.5 очка",
  "Никитин Антон - 23.5 очка",
  "Маланичев Дмитрий - 23.5 очка",
  "Карандашов Роман - 23.5 очка",
  "Федерякин Александр - 23.5 очка",
  "Ачеев Иван - 19.5 очка",
  "Антипов Вадим - 19.5 очка",
  "Джафарова Кристина - 19.5 очка",
  "Арутюнян Альберт - 19.5 очка",
  "Чуев Максим - 13.75 очка",
  "Ачеев Артём - 13.75 очка",
  "Эль Уаий Закария - 13.75 очка",
  "Конкин Роман - 13.75 очка",
  "Иванов Александр - 13.75 очка",
  "Скляров Сергей - 13.75 очка",
  "Болбот Борис - 13.75 очка",
  "Зеленский Владислав - 13.75 очка",
  "Стешин Александр - 5 очков",
  "Карев Сергей - 5 очков",
  "Кульбарак Рустам - 5 очков",
  "Голоманидзе Роберт - 5 очков",
  "Окороков Сергей - 5 очков",
  "Хохлов Илья - 5 очков",
  "Змеев Павел - 5 очков",
  "Акмурадов Бек - 5 очков",
  "Залычныязов Язик - 5 очков",
  "Комаров Алексей - 25 очков",
  "Арутюнян Альберт - 24 очка",
  "Карандашов Роман - 23 очка",
  "Мещеряков Александр - 22 очка",
  "Ягудин Руслан - 20.5 очка",
  "Борисенко Виталий - 20.5 очка",
  "Зеленский Владислав - 18.5 очка",
  "Стешин Александр - 18.5 очка",
  "Скляров Сергей - 15.5 очка",
  "Конкин Роман - 15.5 очка",
  "Змеев Павел - 15.5 очка",
  "Чудин Илья - 15.5 очка",
  "Корженский Андрей - 11.5 очка",
  "Ачеев Артём - 11.5 очка",
  "Окороков Сергей - 11.5 очка",
  "Маланичев Дмитрий - 11.5 очка",
  "Антипов Вадим - 5 очков",
  "Нураки Салах - 5 очков",
  "Шебалдов Алексей - 5 очков",
  "Гурьянов Артемий - 5 очков",
  "Эль Уаий Закария - 5 очков",
  "Никитин Антон - 5 очков",
  "Ачеев Иван - 5 очков",
  "Хохлов Илья - 5 очков",
  "Чуев Максим - 5 очков",
  "Соколов Иван - 20 очков",
  "Петров Вадим - 19 очков",
  "Никитин Антон - 18 очков",
  "Мещеряков Александр - 17 очков",
  "Зеленский Владислав - 15.5 очков",
  "Ачеев Иван - 15.5 очков",
  "Чудин Илья - 13.5 очков",
  "Чуев Максим - 13.5 очков",
  "Болбот Борис - 10.5 очков",
  "Ачеев Артём - 10.5 очков",
  "Нураки Салах - 10.5 очков",
  "Змеев Павел - 10.5 очков",
  "Гурьянов Артемий - 6.5 очков",
  "Маланичев Дмитрий - 6.5 очков",
  "Федерякин Александр - 6.5 очков",
  "Иванов Александр - 6.5 очков",
  "Камнев Виктор - 2.5 очка",
  "Камнев Алексей - 2.5 очка",
  "Антипов Вадим - 2.5 очка",
  "Эль Уаий Закария - 2.5 очка",
];
