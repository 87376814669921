import "./App.css";

import React from "react";
import { Table, Flowbite } from "flowbite-react";
import { Tabs } from "flowbite-react";
import {
  tuesdayParticipants,
  wednesdayParticipants,
} from "./constants/constants";
import { Header } from "./components/Header";
import { mapResults } from "./utils/utils";

function App() {
  const [data, setData] = React.useState([]);
  const [wendsdayData, setWensdayData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);

  React.useEffect(() => {
    const regexes = [
      /^(.+) - (\d+(\.\d+)?) (очков)$/,
      /^(.+) - (\d+(\.\d+)?) (очка)$/,
      /^(.+) - (\d+(\.\d+)?) (очко)$/,
    ];

    const test = wednesdayParticipants.map((item) => {
      let result = "Строка не соответствует ожидаемому формату.";

      for (let i = 0; i < regexes.length; i++) {
        const match = item.match(regexes[i]);

        if (match) {
          const name = match[1];
          const score = parseFloat(match[2]);

          result = {
            name: name,
            score: score,
          };
          break;
        }
      }

      return result;
    });

    const tuesdayData = mapResults(tuesdayParticipants);
    const wensdayData = mapResults(test);
    const allData = mapResults([...tuesdayData, ...wendsdayData]);

    if (allData) {
      const newData = allData.sort((a, b) => (a.score < b.score ? 1 : -1));
      setAllData(newData);
    }

    if (wensdayData) {
      const newData = wensdayData.sort((a, b) => (a.score < b.score ? 1 : -1));
      setWensdayData(newData);
    }

    if (tuesdayData) {
      const newData = tuesdayData.sort((a, b) => (a.score < b.score ? 1 : -1));
      setData(newData);
    }
  }, [wendsdayData]);

  return (
    <>
      <Header />
      <div className="main">
        <Tabs aria-label="Default tabs" style="underline">
          <Tabs.Item active title="Турниры по вторникам">
            <Flowbite>
              <div className="overflow-x-auto">
                <Table hoverable>
                  <Table.Head>
                    <Table.HeadCell>Имя</Table.HeadCell>
                    <Table.HeadCell>Количество очков</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {data.map(({ name, score }) => (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{score}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Flowbite>
          </Tabs.Item>
          <Tabs.Item title="Турниры по средам">
            <Flowbite>
              <div className="overflow-x-auto">
                <Table hoverable>
                  <Table.Head>
                    <Table.HeadCell>Имя</Table.HeadCell>
                    <Table.HeadCell>Количество очков</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {wendsdayData.map(({ name, score }) => (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{score}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Flowbite>
          </Tabs.Item>
          <Tabs.Item title="Итог">
            <Flowbite>
              <div className="overflow-x-auto">
                <Table hoverable>
                  <Table.Head>
                    <Table.HeadCell>Имя</Table.HeadCell>
                    <Table.HeadCell>Количество очков</Table.HeadCell>
                  </Table.Head>
                  <Table.Body className="divide-y">
                    {allData.map(({ name, score }) => (
                      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{score}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            </Flowbite>
          </Tabs.Item>
          <Tabs.Item title="Как считаются очки?">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Сортировка участников по убыванию баллов: Список участников
              сортируется в порядке убывания их баллов, чтобы участники с
              наибольшим количеством баллов были в начале списка.
            </p>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Группировка участников с одинаковым количеством баллов: После
              сортировки участники с одинаковым количеством баллов объединяются
              в группы. Группы образуются путем сбора всех участников, у которых
              количество баллов одинаково.
            </p>

            <p className="text-gray-500 dark:text-gray-400">
              Вычисление среднего балла для группы: Для каждой группы участников
              с одинаковым количеством баллов вычисляется среднее значение их
              баллов. Среднее значение рассчитывается путем деления суммы баллов
              всех участников в группе на количество участников в этой группе.
            </p>
            <p className="text-gray-500 dark:text-gray-400">
              Присвоение мест участникам внутри группы: Участникам внутри каждой
              группы с одинаковым количеством баллов присваиваются одинаковые
              места. Номер места для каждой группы определяется в порядке их
              появления после сортировки.
            </p>
          </Tabs.Item>
          <Tabs.Item title="Пример подсчета">
            <p class="text-gray-500 dark:text-gray-400">
              1. Пугачёв Александр - 16 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              2. Корженский Андрей - 15 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              3. Петров Дмитрий - 14 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              4. Габаткин Кирилл - 13 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              5-6. Масляков Олег - (12 + 11) / 2 = 11.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              5-6. Жидков Владимир - (12 + 11) / 2 = 11.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              7-8. Борисенко Виталий - (10 + 9) / 2 = 9.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              7-8. Бабаков Александр - (10 + 9) / 2 = 9.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              9-12. Ломакин Максим - (8 + 7 + 6 + 5) / 4 = 6.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              9-12. Гуляев Александр - (8 + 7 + 6 + 5) / 4 = 6.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              9-12. Нефёдова Василя - (8 + 7 + 6 + 5) / 4 = 6.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              9-12. Ковалев Михаил - (8 + 7 + 6 + 5) / 4 = 6.5 очков
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              13-16. Смирнов Сергей - (4 + 3 + 2 + 1) / 4 = 2.5 очка
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              13-16. Власов Вячеслав - (4 + 3 + 2 + 1) / 4 = 2.5 очка
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              13-16. Нефёдов Виталий - (4 + 3 + 2 + 1) / 4 = 2.5 очка
            </p>
            <p class="text-gray-500 dark:text-gray-400">
              13-16. Митрофанов Андрей - (4 + 3 + 2 + 1) / 4 = 2.5 очка
            </p>
          </Tabs.Item>
        </Tabs>
      </div>
    </>
  );
}

export default App;
